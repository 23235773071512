// ** React Imports
import { useState, forwardRef } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControlLabel from '@mui/material/FormControlLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'

// ** Custom Components Imports
import CustomChip from 'src/@core/components/mui/chip'

// ** Third Party Imports
import format from 'date-fns/format'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { formatDate, isValidURL, isWindow } from '@/@core/utils/utils'
import { Controller } from 'react-hook-form'
import {
  Button,
  FormHelperText,
  IconButton,
  Stack,
  Switch,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import React from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import { Close } from 'mdi-material-ui'
import { LinearProgressWithLabel } from '@/pages/samples/SampleTabs/UpBamFile'
import PositionedSnackbar from '@/@core/components/message'
import { useMutation } from 'react-query'
import { assumeRole } from '@/@core/services/app.service'
import OSS from 'ali-oss'
import _ from 'lodash'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'

// ** Types
export type DateType = Date | null | undefined

interface PickerProps {
  label?: string
  end: Date | number
  start: Date | number
}

interface Props {
  patient: {
    patientName: string
    infirmaryName: string
    departmentName: string
    caseNumber: string
    birthday: Dayjs | null | string
    doctor: string
    gender: string | null
    race: string | null
    acceptDate: Dayjs | null | string
    tooptip: string[]
    status: boolean
    removeDuplication: boolean
    hasSv: boolean
    svFile: string
    svName: string
    hasCnv: boolean
    cnvFile: string
    cnvName: string
    isFq: boolean
    fqFiles: {
      fileName: string
      process: number
      file?: File
      fileSize?: number
    }[]
  }
  fn?: any
  control: any
  errors: any
  handleUploads?: any
  sampleType: string
  setKey?: any
}

const StepPatient = React.forwardRef((props: Props, ref) => {
  // ** State
  const [sampleField, setSampleField] = useState({
    patient: {
      ...props.patient
    }
  })
  const [abortCheckpoint, setAbortCheckpoint] = React.useState<any[]>([])
  const [client, setClient] = React.useState<OSS>()
  const [retryCount, setRetryCount] = React.useState(0)
  const retryCountMax = 1

  const messageRef = React.useRef<any>()
  const [key, setKey] = React.useState(0)
  const [cnvProcess, setCnvProcess] = React.useState<number>(0)
  const [svProcess, setSvProcess] = React.useState<number>(0)
  const [fqProcess, setFqProcess] = React.useState<number>(0)

  const { t } = useTranslation()

  const temp = React.useMemo(() => [], [sampleField.patient.fqFiles])

  React.useEffect(() => {
    const fqFiles = _.cloneDeep(
      sampleField.patient.fqFiles.filter((item) => item.file)
    )

    fqFiles.forEach((item: any) => {
      if (item.file && !item.file.url) {
        handleFqUpload(item.file)
      }
    })
  }, [sampleField.patient, sampleField.patient.fqFiles])

  const handleSvUpload = async (file: File) => {
    if (file) {
      const formData = new FormData()

      formData.append('file', file)

      const config: AxiosRequestConfig = {
        onUploadProgress: (progressEvent: any) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          )

          setSvProcess(progress)
        }
      }

      try {
        const { data } = await axios.post('/api/oss/upload/', formData, config)

        if (data) {
          setSampleField({
            patient: {
              ...sampleField.patient,
              svName: file.name,
              svFile: file.name,
              hasSv: true
            }
          })

          props.fn('patient', {
            ...sampleField.patient,
            svName: file.name,
            svFile: file.name,
            hasSv: true
          })

          messageRef?.current?.handleClick(t('Upload successful'), 'success')
        }
      } catch (error) {
        messageRef?.current?.handleClick(
          t('Upload failed') + String(error),
          'error'
        )

        console.error('Upload error:', error)
      }
    }
  }

  const handleSvFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      handleSvUpload(event.target.files[0])
    }
  }

  const handleFqUpload = async (file: File) => {
    if (file) {
      const formData = new FormData()

      formData.append('file', file)

      const config: AxiosRequestConfig = {
        onUploadProgress: (progressEvent: any) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          )

          setFqProcess(progress)
        }
      }

      const fqFiles = sampleField.patient.fqFiles
      const flag = _.cloneDeep(fqFiles)
        .filter((item) => !item.file)
        .some((items) => items.fileName === file.name)
      if (flag)
        return messageRef?.current?.handleClick(
          t('文件上传中，请稍后'),
          'warning'
        )

      fqFiles.push({
        fileName: file.name,
        process: 0,
        fileSize: Number(file.size / (1024 * 1024))
      })

      const newSampleField = {
        patient: {
          ...sampleField.patient,
          isFq: true,
          fqFiles: fqFiles.filter((item) => !item.file)
        }
      }

      props.fn('patient', {
        ...sampleField.patient,
        isFq: true,
        fqFiles: fqFiles.filter((item) => !item.file)
      })

      setSampleField(newSampleField)

      try {
        // const { data } = await axios
        //   .post('/api/oss/fastq_uploads/', formData, config)
        //   .catch((err) => {
        //     console.log(err)
        //   })

        // OSS

        setAbortCheckpoint([])
        const client = await handleOss()

        console.log('------------')

        await upFile(
          client,
          file,
          'fastq_uploads',
          setSampleField,
          undefined,
          newSampleField
        )
      } catch (error) {
        messageRef?.current?.handleClick(
          t('Upload failed') + String(error),
          'error'
        )

        console.error('Upload error:', error)
      }
    }
  }

  const handleFqFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if (sampleField.patient.fqFiles.some((item) => item.process !== 100))
    //   return messageRef?.current?.handleClick(
    //     t('文件上传中，请稍后'),
    //     'warning'
    //   )

    if (event.target.files && event.target.files.length > 0) {
      Array.from(event.target.files).map((file) => {
        handleFqUpload(file)
      })
    }
  }

  const handleCnvUpload = async (file: File) => {
    if (file) {
      const formData = new FormData()

      formData.append('file', file)

      const config: AxiosRequestConfig = {
        onUploadProgress: (progressEvent: any) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          )

          setCnvProcess(progress)
        }
      }

      try {
        const { data } = await axios.post('/api/oss/upload/', formData, config)

        if (data) {
          setSampleField({
            patient: {
              ...sampleField.patient,
              cnvName: file.name,
              cnvFile: file.name,
              hasCnv: true
            }
          })

          props.fn('patient', {
            ...sampleField.patient,
            cnvName: file.name,
            cnvFile: file.name,
            hasCnv: true
          })

          messageRef?.current?.handleClick(t('Upload successful'), 'success')
        }
      } catch (error) {
        messageRef?.current?.handleClick(
          t('Upload failed') + String(error),
          'error'
        )

        console.error('Upload error:', error)
      }
    }
  }

  const handleCnvFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      handleCnvUpload(event.target.files[0])
    }
  }

  const handleGetAssumeRole = useMutation({
    mutationFn: async () => await assumeRole()
  })

  const handleOss = async () => {
    // 向您搭建的STS服务获取临时访问凭证。
    const query = await handleGetAssumeRole.mutateAsync()

    // if (!query || !query.SecurityToken) return
    const client = new OSS({
      // yourRegion填写Bucket所在地域。
      region: 'oss-cn-shenzhen',

      // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
      accessKeyId: query.AccessKeyId,
      accessKeySecret: query.AccessKeySecret,

      // 从STS服务获取的安全令牌（SecurityToken）。
      stsToken: query.SecurityToken,

      // 刷新临时访问凭证的时间间隔，单位为毫秒。
      refreshSTSTokenInterval: 200000,

      // 填写Bucket名称。
      bucket: 'glsofort',
      refreshSTSToken: async () => {
        const query = await handleGetAssumeRole.mutateAsync()

        console.log('queryPatient', query)

        return {
          accessKeyId: query.AccessKeyId,
          accessKeySecret: query.AccessKeySecret,
          stsToken: query.SecurityToken
        }
      }
    })

    setClient(client)

    return client
  }

  const upFile = async (
    clients: OSS,
    file: File,
    filePath = 'uploads',
    setProcess?: any,
    option?: OSS.MultipartUploadOptions,
    samples?: any
  ) => {
    const headers = {
      // 指定该Object被下载时的网页缓存行为。
      'Cache-Control': 'no-cache',

      // 指定该Object被下载时的名称。
      'Content-Disposition': file.name,

      // 指定该Object被下载时的内容编码格式。
      'Content-Encoding': 'utf-8',

      // 指定过期时间，单位为毫秒。
      Expires: '1000',

      // // 指定Object的存储类型。
      // 'x-oss-storage-class': 'Standard',

      // // 指定Object标签，可同时设置多个标签。
      // 'x-oss-tagging': 'Tag1=1&Tag2=2',

      // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
      'x-oss-forbid-overwrite': 'false'
    }
    let options: OSS.MultipartUploadOptions = {
      // 获取分片上传进度、断点和返回值。
      progress: (p: any, cpt: any, res: any) => {
        if (clients) {
          // 获取进度以及上传文件分片
          if (samples && samples.patient && samples.patient.isFq) {
            let fqFiles = sampleField.patient.fqFiles.filter(
              (item) => !item.file
            )

            fqFiles = fqFiles.map((items: any) => {
              if (items.fileName === file.name) {
                items.process = p * 100
              }

              return items
            })

            setKey(Math.random())

            props.setKey && props.setKey(Math.random())

            // setProcess({
            //   patient: {
            //     ...sampleField.patient,
            //     isFq: true,
            //     fqFiles
            //   }
            // })

            // console.log('samples', samples?.patient?.fqFiles)

            // console.log('sampleField', sampleField?.patient?.fqFiles)
          } else setProcess(p * 100)

          const flag = abortCheckpoint.some(
            (item) => item.name === `${filePath}/${file.name}`
          )

          if (flag)
            abortCheckpoint.map((item) => {
              if (item.name === `${filePath}/${file.name}`) {
                return cpt
              } else return item
            })
          else abortCheckpoint.push(cpt)

          setAbortCheckpoint(abortCheckpoint)
        }
      },

      // 设置并发上传的分片数量。
      parallel: 4,

      // 设置分片大小。默认值为1 MB，最小值为100 KB。
      partSize: 1024 * 1024 * 2,
      headers,
      mime: 'text/plain',
      timeout: 120000 //设置超时时间,
    }
    if (option) options = option

    // const client = await handleOss()

    console.log('client', clients)

    if (clients) {
      const path = `${filePath}/${encodeURI(file.name)}`

      console.log(path)

      // 上传文件
      const data = (await clients
        .multipartUpload(path, file, {
          ...options
        })
        .catch(async (err) => {
          const strErr = JSON.stringify(err)

          console.log(strErr, err)

          console.log('123', retryCount, abortCheckpoint)

          if (strErr.includes('abort')) return

          //更新token
          // const upClient = await handleOss()

          // 判断是否存在分片数据以及重启次数
          const flag = abortCheckpoint.find(
            (item) => item.name === `${filePath}/${file.name}`
          )

          if (flag && retryCount < retryCountMax) {
            let newRetryCount = retryCount

            newRetryCount += 1

            setRetryCount(newRetryCount)

            const option = {
              ...options
            }

            option.checkpoint = flag

            await upFile(clients, file, filePath, setProcess, option, samples)
          }
        })) as any

      console.log('data', data)
      const url = data?.res?.requestUrls[0]

      if (url && isValidURL(url)) {
        const newAbortCheckpoint = abortCheckpoint.filter(
          (item) => item.name !== `fastq_uploads/${file.name}`
        )

        messageRef?.current?.handleClick(t('Upload successful'), 'success')

        setAbortCheckpoint(newAbortCheckpoint)

        return url
      } else return null
    } else return null
  }

  const deleteFqFile = async (items: { fileName: string; process: number }) => {
    console.log(123, items)

    const params = {
      ...sampleField.patient,
      fqFiles: sampleField.patient.fqFiles.filter(
        (item) => item.fileName !== items.fileName
      )
    }

    setSampleField({
      patient: params
    })

    props.fn('patient', params)

    props.setKey(Math.random())

    const flag = abortCheckpoint.find(
      (item) => item.name === `fastq_uploads/${items.fileName}`
    )

    if (flag && items.process !== 100) {
      await client?.abortMultipartUpload(flag?.name, flag?.uploadId)
    }
  }

  React.useEffect(() => {
    setSampleField({
      patient: {
        ...sampleField.patient,
        ...props.patient
      }
    })
  }, [props])

  React.useImperativeHandle(ref, () => {
    return {
      handleFqUpload,
      deleteFqFile
    }
  })

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={6}>
        <Box className={'flex items-center gap-2'} alignItems="baseline">
          <Typography
            className="font-medium"
            color="text.primary"
            sx={{
              wordBreak: 'keep-all',
              mr: 2
            }}
            flex={2}
            textAlign="right"
          >
            {t('patientName')}:
          </Typography>

          <FormControl fullWidth sx={{ mb: 4, flex: isWindow() ? 7 : 5 }}>
            <TextField
              autoFocus
              placeholder={t("Enter the patient's first name")}
              value={sampleField.patient.patientName}
              onChange={(e) => {
                setSampleField({
                  patient: {
                    ...sampleField.patient,
                    patientName: e.target.value
                  }
                })

                props.fn('patient', {
                  ...sampleField.patient,
                  patientName: e.target.value
                })
              }}
              size={'small'}
            />
          </FormControl>
        </Box>
      </Grid>

      {/* <Grid item xs={12} sm={6}>
        <FormControl fullWidth sx={{ mb: 4 }}>
          <TextField
            autoFocus
            label={t('lastName')}
            placeholder={t("Enter the patient's last name")}
            value={sampleField.patient.lastName}
            onChange={(e) => {
              setSampleField({
                patient: {
                  ...sampleField.patient,
                  lastName: e.target.value
                }
              })

              props.fn('patient', {
                ...sampleField.patient,
                lastName: e.target.value
              })
            }}
          />
        </FormControl>
      </Grid> */}
      <Grid item xs={12} sm={6}>
        <Box
          className={'flex items-center gap-2'}
          alignItems="center !important"
        >
          <Typography
            className="font-medium"
            color="text.primary"
            sx={{
              wordBreak: 'keep-all',
              mr: 2
            }}
            flex={2}
            textAlign="right"
          >
            {t('age')}:
          </Typography>

          <Box
            className={'flex items-center gap-2'}
            alignItems="center"
            sx={{
              flex: isWindow() ? 7 : 5
            }}
          >
            <FormControl fullWidth>
              <TextField
                value={sampleField.patient.birthday}
                onChange={(e) => {
                  setSampleField({
                    patient: {
                      ...sampleField.patient,
                      birthday: e.target.value
                    }
                  })

                  props.fn('patient', {
                    ...sampleField.patient,
                    birthday: e.target.value
                  })
                }}
                size={'small'}
              />
            </FormControl>

            <Typography
              className="font-medium"
              color="text.primary"
              sx={{
                wordBreak: 'keep-all',
                mr: 2
              }}
              flex={1}
              textAlign="right"
            >
              {t('岁')}
            </Typography>
          </Box>

          {/* <Box sx={{ flex: isWindow() ? 7: 5 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(e: any) => {
                  setSampleField({
                    patient: {
                      ...sampleField.patient,
                      birthday: formatDate(e.$d)
                    }
                  })

                  props.fn('patient', {
                    ...sampleField.patient,
                    birthday: formatDate(e.$d)
                  })
                }}
                value={
                  props.patient.birthday ? dayjs(props.patient.birthday) : null
                }
                sx={{
                  width: '100%',
                  '& .MuiInputBase-input': {
                    height: '0.45rem'
                  }
                }}
              />
            </LocalizationProvider>
          </Box> */}
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box className={'flex items-center gap-2'} alignItems="baseline">
          <Typography
            className="font-medium"
            color="text.primary"
            sx={{
              wordBreak: 'keep-all',
              mr: 2
            }}
            flex={2}
            textAlign="right"
          >
            {t('physicianName')}:
          </Typography>

          <FormControl fullWidth sx={{ mb: 4, flex: isWindow() ? 7 : 5 }}>
            <TextField
              placeholder={t("Enter the physician's name")}
              value={sampleField.patient.doctor}
              onChange={(e) => {
                setSampleField({
                  patient: {
                    ...sampleField.patient,
                    doctor: e.target.value
                  }
                })

                props.fn('patient', {
                  ...sampleField.patient,
                  doctor: e.target.value
                })
              }}
              size="small"
            />
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box className={'flex items-center gap-2'} alignItems="baseline">
          <Typography
            className="font-medium"
            color="text.primary"
            sx={{
              wordBreak: 'keep-all',
              mr: 2
            }}
            flex={2}
            textAlign="right"
          >
            {t('Infirmary Name')}:
          </Typography>

          <FormControl fullWidth sx={{ mb: 4, flex: isWindow() ? 7 : 5 }}>
            <TextField
              placeholder={t("Enter the infirmary's name")}
              value={sampleField.patient.infirmaryName}
              onChange={(e) => {
                setSampleField({
                  patient: {
                    ...sampleField.patient,
                    infirmaryName: e.target.value
                  }
                })

                props.fn('patient', {
                  ...sampleField.patient,
                  infirmaryName: e.target.value
                })
              }}
              size={'small'}
            />
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box className={'flex items-center gap-2'} alignItems="baseline">
          <Typography
            className="font-medium"
            color="text.primary"
            sx={{
              wordBreak: 'keep-all',
              mr: 2
            }}
            flex={2}
            textAlign="right"
          >
            {t('Department Name')}:
          </Typography>

          <FormControl fullWidth sx={{ mb: 4, flex: isWindow() ? 7 : 5 }}>
            <TextField
              placeholder={t("Enter the department's name")}
              value={sampleField.patient.departmentName}
              onChange={(e) => {
                setSampleField({
                  patient: {
                    ...sampleField.patient,
                    departmentName: e.target.value
                  }
                })

                props.fn('patient', {
                  ...sampleField.patient,
                  departmentName: e.target.value
                })
              }}
              size={'small'}
            />
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box className={'flex items-center gap-2'} alignItems="baseline">
          <Typography
            className="font-medium"
            color="text.primary"
            sx={{
              wordBreak: 'keep-all',
              mr: 2
            }}
            flex={2}
            textAlign="right"
          >
            {t('Case Number')}:
          </Typography>

          <FormControl fullWidth sx={{ mb: 4, flex: isWindow() ? 7 : 5 }}>
            <TextField
              size="small"
              placeholder={t('Enter the Case Number')}
              value={sampleField.patient.caseNumber}
              onChange={(e) => {
                setSampleField({
                  patient: {
                    ...sampleField.patient,
                    caseNumber: e.target.value
                  }
                })

                props.fn('patient', {
                  ...sampleField.patient,
                  caseNumber: e.target.value
                })
              }}
            />
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box className={'flex items-center gap-2'} alignItems="baseline">
          <Typography
            className="font-medium"
            color="text.primary"
            sx={{
              wordBreak: 'keep-all',
              mr: 2
            }}
            flex={2}
            textAlign="right"
          >
            {t('sex')}:
          </Typography>

          <FormControl fullWidth sx={{ flex: isWindow() ? 7 : 5 }}>
            <Select
              size="small"
              value={sampleField.patient.gender}
              onChange={(e) => {
                setSampleField({
                  patient: {
                    ...sampleField.patient,
                    gender: e.target.value
                  }
                })

                props.fn('patient', {
                  ...sampleField.patient,
                  gender: e.target.value
                })
              }}
              labelId="select-user-type"
              defaultValue=""
              IconComponent={ExpandCircleDownOutlinedIcon}
            >
              <MenuItem value="unknown">{t('unknown')}</MenuItem>

              <MenuItem value="male">{t('male')}</MenuItem>

              <MenuItem value="female">{t('female')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box className={'flex items-center gap-2'} alignItems="baseline">
          <Typography
            className="font-medium"
            color="text.primary"
            sx={{
              wordBreak: 'keep-all',
              mr: 2
            }}
            flex={2}
            textAlign="right"
          >
            {t('Ethnicity')}:
          </Typography>

          <FormControl fullWidth sx={{ mb: 4, flex: isWindow() ? 7 : 5 }}>
            <Select
              size="small"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={sampleField.patient.race}
              onChange={(e: any) => {
                setSampleField({
                  patient: {
                    ...sampleField.patient,
                    race: e.target.value
                  }
                })

                props.fn('patient', {
                  ...sampleField.patient,
                  race: e.target.value
                })
              }}
              IconComponent={ExpandCircleDownOutlinedIcon}
            >
              <MenuItem value={'EastAsia'}>{t('East Asia')}</MenuItem>

              <MenuItem value={'America'}>{t('America')}</MenuItem>

              <MenuItem value={'Mediterranean'}>{t('Mediterranean')}</MenuItem>

              <MenuItem value={'WhiteRace'}>{t('WhiteRace')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box
          className={'flex items-center gap-2'}
          alignItems="center !important"
        >
          <Typography
            className="font-medium"
            color="text.primary"
            sx={{
              wordBreak: 'keep-all',
              mr: 2
            }}
            flex={2}
            textAlign="right"
          >
            {t('Receive Date')}:
          </Typography>

          <Box
            sx={{
              flex: isWindow() ? 7 : 5
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(e: any) => {
                  setSampleField({
                    patient: {
                      ...sampleField.patient,
                      acceptDate: formatDate(e.$d)
                    }
                  })

                  props.fn('patient', {
                    ...sampleField.patient,
                    acceptDate: formatDate(e.$d)
                  })
                }}
                value={
                  props.patient.acceptDate
                    ? dayjs(props.patient.acceptDate)
                    : null
                }
                sx={{
                  width: '100%',
                  '& .MuiInputBase-input': {
                    height: '0.45rem'
                  }
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box
          className={'flex items-center gap-2'}
          alignItems="center !important"
        >
          <Typography
            className="font-medium"
            color="text.primary"
            sx={{
              wordBreak: 'keep-all',
              mr: 2
            }}
            flex={2}
            textAlign="right"
          >
            {t('tips')}:
          </Typography>

          <Box
            sx={{
              flex: isWindow() ? 7 : 5
            }}
          >
            <FormControl component="fieldset" size="small">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="email"
                  label="Email"
                  control={<Checkbox size="small" />}
                />

                <FormControlLabel
                  value="sms"
                  label="SMS"
                  control={<Checkbox size="small" />}
                />

                <FormControlLabel
                  control={<Checkbox size="small" />}
                  value="push-notification"
                  label="Push Notification"
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box className={'flex items-center gap-2'} alignItems="baseline">
          <FormControl fullWidth sx={{ mb: 4 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={sampleField.patient.status}
                  onChange={(e: any) => {
                    setSampleField({
                      patient: {
                        ...sampleField.patient,
                        status: e.target.checked
                      }
                    })

                    props.fn('patient', {
                      ...sampleField.patient,
                      status: e.target.checked
                    })
                  }}
                />
              }
              labelPlacement="start"
              label={t('Immediate analysis or not')}
            />
          </FormControl>

          {props.sampleType === 'fastq' || props.sampleType === 'naf' ? (
            <FormControl fullWidth sx={{ mb: 4 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={sampleField.patient.removeDuplication}
                    onChange={(e: any) => {
                      setSampleField({
                        patient: {
                          ...sampleField.patient,
                          removeDuplication: e.target.checked
                        }
                      })

                      props.fn('patient', {
                        ...sampleField.patient,
                        removeDuplication: e.target.checked
                      })
                    }}
                  />
                }
                labelPlacement="start"
                label={t('Remove Duplicates or not')}
              />
            </FormControl>
          ) : (
            ''
          )}
        </Box>
      </Grid>

      {props.sampleType === 'vcf' ? (
        <Grid item xs={12} sm={12}>
          <Stack spacing={4} direction="row">
            <Box flex={1}>
              <Button variant="contained" component="label">
                <input
                  onChange={handleSvFileChange}
                  type="file"
                  hidden
                  accept=".sv.vcf, .sv.vcf.gz"
                />
                sv{t('upload')}
              </Button>

              <Box>
                {sampleField.patient.hasSv ? (
                  <Typography>
                    {sampleField.patient.svName}

                    <IconButton
                      onClick={() => {
                        const params = {
                          ...sampleField.patient,
                          svName: '',
                          svFile: '',
                          hasSv: false
                        }

                        setSampleField({
                          patient: params
                        })

                        props.fn('patient', params)

                        setSvProcess(0)
                      }}
                    >
                      <Close sx={{ fontSize: '1.125rem' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography>{t('Unselected File')}</Typography>
                )}
              </Box>

              <LinearProgressWithLabel value={svProcess} />
            </Box>

            <Box flex={1}>
              <Button variant="contained" component="label">
                <input
                  onChange={handleCnvFileChange}
                  type="file"
                  hidden
                  accept=".exoncnv.vcf,.cnv.vcf, .cnv.vcf.gz"
                />
                cnv{t('upload')}
              </Button>

              <Box>
                {sampleField.patient.hasCnv ? (
                  <Typography>
                    {sampleField.patient.cnvName}

                    <IconButton
                      onClick={() => {
                        const params = {
                          ...sampleField.patient,
                          cnvName: '',
                          cnvFile: '',
                          hasCnv: false
                        }

                        setSampleField({
                          patient: params
                        })

                        props.fn('patient', params)

                        setCnvProcess(0)
                      }}
                    >
                      <Close sx={{ fontSize: '1.125rem' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography>{t('Unselected File')}</Typography>
                )}
              </Box>

              <LinearProgressWithLabel value={cnvProcess} />
            </Box>
          </Stack>
        </Grid>
      ) : props.sampleType === 'fastq' || props.sampleType === 'naf' ? (
        <Grid item xs={12} sm={6}>
          <Stack spacing={4} direction="row">
            <Box flex={1}>
              {/* <Button variant="contained" component="label">
                <input
                  onChange={handleFqFileChange}
                  type="file"
                  hidden
                  accept=".fastq.gz, .fq.gz,.naf"
                />
                Fastq{t('upload')}
              </Button> */}

              {/* <Box>
                {sampleField.patient.fqFiles &&
                sampleField.patient.fqFiles.length ? (
                  <Box>
                    {sampleField.patient.fqFiles.map((items) => {
                      return (
                        <Box key={`${items.fileName}_${items.process}`}>
                          <Typography>
                            {items.fileName}

                            <IconButton
                              onClick={async () => {
                                const params = {
                                  ...sampleField.patient,
                                  fqFiles: sampleField.patient.fqFiles.filter(
                                    (item) => item.fileName !== items.fileName
                                  )
                                }

                                console.log(params)

                                setSampleField({
                                  patient: params
                                })

                                props.fn('patient', params)

                                const flag = abortCheckpoint.find(
                                  (item) =>
                                    item.name ===
                                    `fastq_uploads/${items.fileName}`
                                )

                                console.log(flag, client)

                                if (flag && items.process !== 100) {
                                  await client?.abortMultipartUpload(
                                    flag?.name,
                                    flag?.uploadId
                                  )
                                }
                              }}
                            >
                              <Close sx={{ fontSize: '1.125rem' }} />
                            </IconButton>
                          </Typography>

                          <LinearProgressWithLabel value={items.process ?? 0} />
                        </Box>
                      )
                    })}
                  </Box>
                ) : (
                  <Typography>{t('Unselected File')}</Typography>
                )}
              </Box> */}
            </Box>
          </Stack>
        </Grid>
      ) : (
        ''
      )}

      <PositionedSnackbar ref={messageRef} />
    </Grid>
  )
})

export default StepPatient
