// ** React Imports
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react'

// ** Next Import
import Link from 'next/link'

// ** MUI Imports
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography, { TypographyProps } from '@mui/material/Typography'

import OSS from 'ali-oss'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack
} from '@mui/material'
import Translations from '@/layouts/components/Translations'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import {
  assumeRole,
  createCatalog,
  createUserCustomUploads,
  deleteUserCustomUploads,
  editUserCustomUploads,
  getUserCustomUploads,
  getUserDirectUploads,
  UserCustomUploads,
  UserDirectUploads
} from '@/@core/services/app.service'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { NObject } from '@/@core/components/filter-variants'
import { formatDate } from '@/@core/utils/utils'
import React from 'react'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root': {
    maxWidth: 1200,
    minHeight: 500
  }
}))

interface Props {
  fn?: (file: File[]) => void
  uploadFn?: (file: File[]) => void
  accept?: string
  disableList?: boolean
  sx?: any
}

// Styled component for the upload image inside the dropzone area
const Img = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(15.75)
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    width: 160
  }
}))

const TypographyEllipsis = styled(Typography)(() => ({
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '0.875rem'
}))

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

const OssFileUploaderMultiple = React.forwardRef((props: Props, ref) => {
  const { t } = useTranslation()

  // ** State
  const [files, setFiles] = useState<any[]>([])
  const [ossFileListOpen, setOssFileListOpen] = useState(false)
  const [ossFileList, setOssFileList] = useState<any[]>([])

  const handleGetAssumeRole = useMutation({
    mutationFn: async () => await assumeRole()
  })

  const fetchUserCustomUploads = useMutation({
    mutationFn: async () => await getUserDirectUploads()
  })

  const putCatalog = useMutation({
    mutationFn: async (key: string) => await createCatalog({ fileKey: key })
  })

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('ID'), width: 80 },
    { field: 'name', headerName: t('File Name'), flex: 1 },
    {
      field: 'size',
      headerName: t('Size'),
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      renderCell: (row) => {
        const size = row.row.size

        if (size) {
          return (
            <TypographyEllipsis
              sx={{
                textAlign: 'left'
              }}
            >
              {`${(size / 1024 / 1024).toFixed(0)}MB`}
            </TypographyEllipsis>
          )
        } else {
          return (
            <TypographyEllipsis
              sx={{
                textAlign: 'left'
              }}
            >
              {''}
            </TypographyEllipsis>
          )
        }
      }
    },
    {
      field: 'lastModified',
      headerName: t('submitAt'),
      width: 180,
      renderCell: (row) => {
        return formatDate(row.row.lastModified)
      }
    },
    {
      field: 'isCreated',
      headerName: t('Is Created'),
      width: 120,
      renderCell: (row) => {
        return t(row.row.isCreated ? 'true' : 'false')
      }
    }
  ]

  const getOssList = async () => {
    const userCustomData = await fetchUserCustomUploads.mutateAsync()

    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const email = user.email as string
    const first = email.split('@')[0]
    const second = email.split('@')[1].split('.com')[0]
    const key = `${first}-${second}/`
    const useKey = `${user.directUploadFolder}/`

    const client = await handleOss()

    console.log(client, useKey, key)

    if (client) {
      const data = await client.list(
        {
          prefix: `${'user_direct_uploads'}/${useKey ? useKey : key}`,
          'max-keys': 1000
        },
        {}
      )

      console.log(data)

      if (data && data.objects) {
        const objects = data.objects
          .filter((item) => item.size)
          .map((item, index) => {
            item.name = item.name.split(useKey ? useKey : key)[1]

            const temp = userCustomData.list.find((i: UserDirectUploads) =>
              item.url.includes(i.fileName as string)
            )

            console.log(temp)

            return {
              ...item,
              id: index,
              isCreated: temp ? Boolean(temp.isCreated) : false
            }
          })
          .sort((a, b) => {
            if (a.isCreated && !b.isCreated) return 1
            else if (!a.isCreated && b.isCreated) return -1
            else return 0
          })

        setOssFileList(objects)

        console.log(objects)
      }
    }
  }

  const handleOss = async () => {
    // 向您搭建的STS服务获取临时访问凭证。
    const query = await handleGetAssumeRole.mutateAsync()

    // if (!query || !query.SecurityToken) return
    const client = new OSS({
      // yourRegion填写Bucket所在地域。
      region: 'oss-cn-shenzhen',

      // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
      accessKeyId: query.AccessKeyId,
      accessKeySecret: query.AccessKeySecret,

      // 从STS服务获取的安全令牌（SecurityToken）。
      stsToken: query.SecurityToken,

      // 刷新临时访问凭证的时间间隔，单位为毫秒。
      refreshSTSTokenInterval: 200000,

      // 填写Bucket名称。
      bucket: 'glsofort',
      refreshSTSToken: async () => {
        const query = await handleGetAssumeRole.mutateAsync()

        console.log('query', query.Expiration)

        return {
          accessKeyId: query.AccessKeyId,
          accessKeySecret: query.AccessKeySecret,
          stsToken: query.SecurityToken
        }
      }
    })

    return client
  }

  const handleOssClick = async () => {
    setOssFileListOpen(true)

    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const email = user.email as string
    const first = email.split('@')[0]
    const second = email.split('@')[1].split('.com')[0]
    const key = `${first}-${second}/`
    const useKey = `${user.directUploadFolder}/`

    const createData = await putCatalog.mutateAsync(
      `${'user_direct_uploads'}/${useKey ? useKey : key}`
    )

    console.log('createData', createData)

    if (Array.isArray(createData)) {
      //true
      await getOssList()
    } else {
      // false
    }
  }

  React.useImperativeHandle(ref, () => {
    return {
      handleOssClick
    }
  })

  return (
    <Fragment>
      {/* <div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'column', 'row'],
            alignItems: 'center'
          }}
        >
          <Img alt="Upload img" src="/images/misc/upload.png" />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: ['center', 'center', 'inherit'],
              cursor: 'pointer'
            }}
            onClick={async () => {
              await handleOssClick
            }}
          >
            <HeadingTypography variant="h5">
              <Translations text="选择OSS目录文件开始新建样本"></Translations>
            </HeadingTypography>

            <Typography
              color="textSecondary"
              sx={{ '& a': { color: 'primary.main', textDecoration: 'none' } }}
            >
              <Translations text="选择对应用户OSS目录文件开始新建"></Translations>
            </Typography>
          </Box>
        </Box>
      </div> */}

      <BootstrapDialog
        onClose={() => {
          setOssFileListOpen(false)

          setOssFileList([])

          setFiles([])
        }}
        aria-labelledby="customized-dialog-title"
        open={ossFileListOpen}
      >
        <DialogTitle>
          <Translations text={'OSS 文件列表'}></Translations>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={() => {
            setOssFileListOpen(false)

            setOssFileList([])

            setFiles([])
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers sx={{ width: 1200 }}>
          <Stack height={'100%'}>
            <Stack my={2} justifyContent={'end'}>
              <Box textAlign={'right'}>
                <Button
                  onClick={() => {
                    getOssList()
                  }}
                  variant="contained"
                  size="small"
                >
                  {t('Refresh')}
                </Button>
              </Box>
            </Stack>

            <DataGrid
              rows={ossFileList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5
                  }
                }
              }}
              pageSizeOptions={[5, 10, 20]}
              disableRowSelectionOnClick
              checkboxSelection
              onRowSelectionModelChange={(rowKey) => {
                console.log(rowKey)

                const data = rowKey.map((item) => {
                  return ossFileList.find((i) => i.id == item)
                })

                setFiles(data)
              }}
              sx={{
                minHeight: 250
              }}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Stack
            width={'100%'}
            direction={'row'}
            spacing={4}
            margin={'10px 0'}
            alignItems="center"
            justifyContent={'center'}
          >
            <Button
              autoFocus
              variant="outlined"
              onClick={() => {
                setOssFileListOpen(false)

                setOssFileList([])

                setFiles([])
              }}
            >
              {t('cancel')}
            </Button>

            <Button
              autoFocus
              variant="contained"
              onClick={() => {
                props.fn && props.fn(files)

                setOssFileListOpen(false)

                setOssFileList([])

                setFiles([])
              }}
            >
              {t('create')}
            </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </Fragment>
  )
})

export default OssFileUploaderMultiple
