import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Switch,
  Typography
} from '@mui/material'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import Translations from '@/layouts/components/Translations'

const schema = yup.object().shape({
  panelName: yup.string().min(3).required(),
  geneNames: yup.string().required()
})

interface Panel {
  id?: number
  userId: number | null
  panelName: string | null
  geneNames: string | null
  isDelete: boolean | null
  isPublic: boolean | null
  createdAt: Date | null
  updatedAt: Date | null
}

interface PanelDialogProps {
  open: boolean
  onClose: () => void
  panelToEdit: Panel | null
  onSave: (panel: Panel) => void
  type: 'view' | 'change'
}

const PanelDialog: React.FC<PanelDialogProps> = ({
  open,
  onClose,
  panelToEdit,
  onSave,
  type
}) => {
  const defaultValues = {
    panelName: panelToEdit ? panelToEdit.panelName : '',
    geneNames: panelToEdit ? panelToEdit.geneNames : '',
    isPublic: panelToEdit ? (panelToEdit.isPublic ? true : false) : false
  }
  const [panel, setPanel] = useState(defaultValues)
  const { t } = useTranslation()

  // ** Hooks
  const {
    control: Control,
    handleSubmit: handleSubmit,
    formState: { errors: Errors },
    reset
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)
  })

  const handleSave = () => {
    if (panelToEdit) {
      // 编辑模式
      const arrValue = panel.geneNames
        ? Array.from(
            new Set([
              ...panel.geneNames
                .split(' ')
                .join(',')
                .split('，')
                .join(',')
                .split('\n')
                .join(',')
                .split(',')
                .filter((item) => item)
            ])
          )
        : []

      onSave({
        ...panelToEdit,
        panelName: panel.panelName,
        geneNames: arrValue.join(','),
        isPublic: panel.isPublic
      })
    } else {
      // 创建模式
      const user = JSON.parse(localStorage.getItem('user') || '{}')

      const arrValue = panel.geneNames
        ? Array.from(
            new Set([
              ...panel.geneNames
                .split(' ')
                .join(',')
                .split('，')
                .join(',')
                .split('\n')
                .join(',')
                .split(',')
                .filter((item) => item)
            ])
          )
        : []

      onSave({
        panelName: panel.panelName,
        geneNames: arrValue.join(','),
        isDelete: false,
        createdAt: new Date(),
        userId: user.id,
        updatedAt: null,
        isPublic: panel.isPublic
      })
    }

    onClose()
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      {type === 'change' ? (
        <form onSubmit={handleSubmit(handleSave)}>
          <DialogTitle>
            <Translations
              text={panelToEdit ? 'Edit Panel' : 'Create Panel'}
            ></Translations>
          </DialogTitle>

          <DialogContent dividers>
            <FormControl fullWidth>
              <Controller
                name="panelName"
                control={Control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    onChange={(event) => {
                      setPanel({ ...panel, panelName: event.target.value })

                      onChange(event)
                    }}
                    error={Boolean(Errors.panelName)}
                    aria-describedby="stepper-linear--panelName"
                    margin="dense"
                    label={t('panelName')}
                  />
                )}
              />

              {Errors.panelName && (
                <FormHelperText
                  sx={{ color: 'error.main' }}
                  id="stepper-linear--panelName"
                >
                  {Errors.panelName.message}
                </FormHelperText>
              )}
            </FormControl>

            <TextField
              margin="dense"
              label={t('geneName')}
              fullWidth
              value={panel.geneNames}
              multiline
              rows={4}
              onChange={(event) => {
                console.log(event.target.value)

                setPanel({ ...panel, geneNames: event.target.value })
              }}
            />

            <Typography fontSize={12}>
              *
              <Translations text="Each gene name needs to be separated by a comma."></Translations>
            </Typography>

            <FormControlLabel
              required
              control={
                <Switch
                  checked={panel.isPublic ?? false}
                  onChange={(e) => {
                    setPanel({ ...panel, isPublic: e.target.checked })
                  }}
                />
              }
              label={t('Public or not')}
            />
          </DialogContent>

          <DialogActions>
            <Stack
              width={'100%'}
              alignItems="center"
              justifyContent={'center'}
              direction={'row'}
              spacing={6}
              marginTop={4}
            >
              <Button variant="outlined" onClick={onClose} color="primary">
                <Translations text="cancel"></Translations>
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  if (panel.panelName && panel.panelName.length >= 3) {
                    handleSave()
                  }
                }}
                type="submit"
                color="primary"
              >
                <Translations text="save"></Translations>
              </Button>
            </Stack>
          </DialogActions>
        </form>
      ) : (
        <>
          <DialogTitle>{t('gene')}Panel</DialogTitle>

          <DialogContent dividers>
            <Typography
              width={'100%'}
              fontSize={14}
              sx={{
                wordBreak: 'break-all',
                wordWrap: 'break-word'
              }}
            >
              {panel.geneNames}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Stack direction={'row'} spacing={6} marginTop={4}>
              <Button variant="outlined" onClick={onClose} color="primary">
                {t('close')}
              </Button>
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default PanelDialog
